<template>
  <div class="serve">
    <heard-vue></heard-vue>
    <!-- 主题区域开始 -->
    <div class="width-100 posi-rel" v-if="serveObj">
      <!-- 顶部 -->
      <div class="serve-heard width-100 direction">
        <div class="font-weight-bolder">STFULFILL Offers The Best Dropshipping Services.</div>
        <div class="serve-login flex-center pointer" @click="navTo('/login')">
          JOIN FOR FREE
        </div>
      </div>
      <!-- 数据区域 -->
      <div class="serve-statistics width-100">
        <div class="statistics-data space-between">
          <div class="data-item">
            <div>{{ serveObj.year }}</div>
            <div>SINCE</div>
          </div>
          <div class="data-item">
            <div>{{ serveObj.customer }}</div>
            <div>CLIENT</div>
          </div>
          <div class="data-item">
            <div>{{ serveObj.order }}</div>
            <div>ORDER</div>
          </div>
        </div>
        <div class="width-100 flex-center offer-view">
          <div class="offer-border"></div>
          <div>what we offer you</div>
          <div class="offer-border"></div>
        </div>
        <div class="offer-step">
          <div
            class="offer-step-item width-100 row"
          >
            <div class="item-img one">
              <img :src="serveObj.provide[0].img" alt="" />
            </div>
            <div class="item-text height-100 one">
              <div class="item-text-title width-100 row-start">
                <div class="text-num flex-center">01</div>
                <div class="title-name">
                  <div>{{ serveObj.provide[0].title }}</div>
                  <div>{{ serveObj.provide[0].sub_title }}</div>
                </div>
              </div>
              <div class="item-text-content">
                {{ serveObj.provide[0].content }}
              </div>
            </div>
          </div>
          <div
            class="offer-step-item width-100 row"
         
          >
            <div class="item-text height-100 one">
              <div class="item-text-title width-100 row-start">
                <div class="text-num flex-center">02</div>
                <div class="title-name">
                  <div>{{ serveObj.provide[1].title }}</div>
                  <div>{{ serveObj.provide[1].sub_title }}</div>
                </div>
              </div>
              <div class="item-text-content item-text-content-one">
                {{ serveObj.provide[1].content }}
              </div>
            </div>
            <div class="item-img one flex-center">
              <img :src="serveObj.provide[1].img" class="height-100" alt="" />
            </div>
          </div>
          <div
            class="offer-step-item offer-step-item-one width-100 row"
           
          >
            <div class="item-img one flex-center">
              <img :src="serveObj.provide[2].img" class="height-100" alt="" />
            </div>
            <div class="item-text height-100 one">
              <div class="item-text-title width-100 row-start">
                <div class="text-num flex-center">03</div>
                <div class="title-name">
                  <div>{{ serveObj.provide[2].title }}</div>
                  <div>{{ serveObj.provide[2].sub_title }}</div>
                </div>
              </div>
              <div class="item-text-content item-text-content-two">
                <div>
                  {{ serveObj.provide[2].content }}
                </div>
                <div class="width-100 row-end">
                  <div class="bg-color flex-center need-button pointer" @click="navTo('/login')">I NEED</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 供应商优化区域 -->
      <div class="supplier width-100" v-if="serveObj">
        <div class="supplier-center height-100">
          <div class="width-100 flex-center supplier-title">Supplier optimization</div>
          <div
            class="row-start supplier-messy-layout"
          >
            <img :src="serveObj.supplier[0].img" alt="" />
            <div class="messy-text">
              <div>
                {{ serveObj.supplier[0].content }}
              </div>
              <div>
                {{ serveObj.supplier[1].content }}
              </div>
            </div>
            <img :src="serveObj.supplier[1].img" class="posi-rel img-one" alt="" />
          </div>
        </div>
      </div>
      <!--我们的优势 -->
      <div class="advantage width-100">
        <div class="advantage-center direction">
          <div class="flex-center advantage-title">
            <div class="advantage-border"></div>
            <div>Our advantage</div>
            <div class="advantage-border"></div>
          </div>
          <div class="width-100 flex-center advantage-item" v-if="serveObj">
            <div
              class="item-center direction"
              v-for="(item, index) in serveObj.advantage"
              :key="index"
            >
              <img :src="item.img" alt="" />
              <div class="item-center-title">{{ item.title }}</div>
              <div class="width-100">
                {{ item.content }}
              </div>
            </div>
          </div>
          <div class="advantage-text">
            Dropshipping's system is free to use. We are trying to develop more features to
            make the process easier!
          </div>
        </div>
      </div>
      <!-- tab展示区域 -->
      <div class="tab-exhibit width-100 row-start">
        <div class="exhibit-item-tab">
          <div
            class="item-tab mb-3"
            v-for="(item, index) in serveObj.process"
            :key="index"
            @click="tabClick(index)"
          >
            <div class="flex-center tab-view pointer" v-if="tabId !== index">
              {{ item.title }}
            </div>
            <div class="posi-rel pointer tab-img" v-else>
              <img src="../../assets/serve-9.png" class="width-100 height-100" alt="" />
              <div
                class="posi-abs width-100 height-100 flex-center top-0 left-0 main-color"
              >
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="exhibit-center direction">
          <img :src="serveObj.process[tabId].img" alt="" />
          <div>
            {{serveObj.process[tabId].content}}
          </div>
        </div>
      </div>
      <!-- 最底部 -->
      <div class="direction serve-join width-100">
        <div> Dropshipping facilitates order fulfillment and explore your profits today!</div>
        <div class="flex-center bg-color pointer">JOIN NOW</div>
      </div>
      <img
        src="../../assets/about-5.png"
        v-if="!emailShow"
        class="emain-img posi-abs pointer"
        alt=""
        @click="emailShow = true"
      />
    </div>
    <mail-vue v-if="emailShow" @hide="emailShow = false"></mail-vue>
    <!-- 主题区域结束 -->
    <feet-vue></feet-vue>
  </div>
</template>

<script>
import heardVue from "../../components/heard.vue";
import feetVue from "../../components/feet.vue";
import mailVue from "../../components/mail.vue";
import api from "../../api/index";
export default {
  components: {
    heardVue,
    feetVue,
    mailVue,
  },

  data() {
    return {
      emailShow: false,
      tabList: [
        {
          title: "自动履行",
          id: 1,
        },
        {
          title: "运费预估",
          id: 2,
        },
        {
          title: "多店管理",
          id: 3,
        },
        {
          title: "预设物流",
          id: 4,
        },
      ],
      tabId: 0,
      serveObj: null,
    };
  },

  created() {
    this.getServe();
  },

  mounted() {},

  methods: {
    getServe() {
      api.getServe().then((res) => {
        this.serveObj = res.data;
      });
    },
    navTo(url) {
      this.$router.push(url);
    },
    tabClick(id) {
      this.tabId = id;
    },
  },
};
</script>
<style lang="less" scoped>
.serve {
  width: 100%;
  .serve-heard {
    height: 499px;
    padding: 106px 0 139px 0;
    background: url("../../assets/1.jpg") no-repeat;
    background-size: 100% 100%;
    font-weight: bold;
    color: #ffffff;
    font-size: 44px;
    white-space: nowrap;
    
    .serve-login {
      width: 201px;
      height: 52px;
      background: #ff652b;
      border-radius: 26px;
      border: 1px solid #ffffff;
      font-weight: 400;
      font-size: 18px;
      margin-top: 140px;
    }
  }
  // 数据区域
  .serve-statistics {
    background: #f8f8f8;
    padding: 66px 0 90px 0;
    .statistics-data {
      width: 1008px;
      margin: 0 auto;
      margin-bottom: 157px;
      .data-item {
        text-align: center;
        font-weight: bold;
        color: #ff652b;
        font-size: 44px;
        div:last-child {
          font-weight: 400;
          color: #666666;
          font-size: 24px;
          margin-top: 20px;
        }
      }
    }
    .offer-view {
      font-weight: bold;
      color: #666666;
      font-size: 32px;
      margin-bottom: 60px;
      .offer-border {
        width: 122px;
        border: 1px solid #707070;
      }
      div:nth-child(2) {
        margin: 0 23px;
      }
    }
    .offer-step {
      width: 1226px;
      margin: 0 auto;
      .offer-step-item {
        background: #ffffff;
        height: 330px;
        margin-bottom: 30px;
        .item-img{
          width: 100%;
          height: 100%;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .item-text-title {
          height: 139px;
          padding: 56px 0 0 56px;
          border-bottom: 1px solid rgba(112, 112, 112, 0.5);
          .text-num {
            width: 36px;
            height: 36px;
            background: #f0ad66;
            font-weight: bold;
            color: #ffffff;
            font-size: 26px;
          }
          .title-name {
            margin-left: 14px;
            font-weight: 400;
            color: #999999;
            font-size: 14px;
            div:first-child {
              font-weight: bold;
              color: #333333;
              font-size: 24px;
              margin-bottom: 4px;
            }
          }
        }
        .item-text-content {
          font-weight: 400;
          color: #666666;
          font-size: 16px;
          padding: 27px 76px 0 55px;
		  font-family: Noto Serif;
        }
        .item-text-content-one {
          padding: 20px 55px 0 55px;
        }
        .item-text-content-two {
          padding: 20px 50px 0 50px;
        }
      }
      .offer-step-item-one {
        height: 344px;
        margin-bottom: 0;
        .need-button {
          width: 116px;
          height: 36px;
          border-radius: 21px;
          font-weight: bold;
          color: #ffffff;
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
  // 供应商优化区域
  .supplier {
    height: 630px;
    background: #edf4fe;
    .supplier-center {
      width: 1226px;
      margin: 0 auto;
      padding-top: 36px;
      .supplier-title {
        font-weight: bold;
        color: #333333;
        font-size: 44px;
        margin-bottom: 64px;
      }
      .supplier-messy-layout {
        img {
          width: 356px;
          height: 356px;
        }
        .messy-text {
          font-weight: 400;
          color: #333333;
          font-size: 18px;
          padding-top: 43px;
          div:first-child {
            position: relative;
            left: -10px;
            width: 401px;
            margin-bottom: 107px;
          }
          div:last-child {
            width: 466px;
          }
        }
        .img-one {
          top: 112px;
        }
      }
    }
  }
  // 我们的优势区域
  .advantage {
    padding: 90px 0 51px 0;
    background: #f8f8f8;
    .advantage-text {
      font-weight: bold;
      color: #333333;
      font-size: 32px;
      // text-align: center;
      width: 67%;
    }
    .advantage-title {
      font-weight: bold;
      color: #666666;
      font-size: 32px;
      margin-bottom: 60px;
      div:nth-child(2) {
        margin: 0 23px;
      }
      .advantage-border {
        width: 122px;
        border: 1px solid #707070;
      }
    }
    .advantage-item {
      margin-bottom: 90px;
      font-weight: 400;
      color: #333333;
      font-size: 16px;
      align-items: flex-start;
      img {
        width: 200px;
        height: 200px;
        margin-bottom: 32px;
      }
      .item-center {
        width: 238px;
        margin-right: 170px;
        .item-center-title {
          font-weight: bold;
          font-size: 28px;
          margin-bottom: 24px;
        }
      }
      .item-center:last-child {
        margin-right: 0;
      }
    }
  }
  // tab展示区域
  .tab-exhibit {
    background: #f5f7fd;
    padding: 0 0 80px 347px;
    flex-wrap: nowrap;
    .exhibit-item-tab {
      padding-top: 44px;
      margin-right: 213px;
      .tab-view {
        width: 353px;
        height: 60px;
        background: #ffffff;
        border: 1px solid #ff652b;
        font-weight: bold;
        color: #333333;
        font-size: 24px;
      }
      .tab-img {
        width: 361px;
        height: 60px;
        font-weight: bold;
        font-size: 24px;
      }
    }
    .exhibit-center {
      width: 341px;
      font-weight: 400;
      color: #707070;
      font-size: 16px;
      padding-top: 70px;
      img {
        width: 294px;
        height: 207px;
      }
    }
  }
  //最底部
  .serve-join {
    height: 298px;
    background: #f8f8f8;
    padding-top: 85px;
    font-weight: bold;
    color: #333333;
    font-size: 40px;
    text-align: center;
    div:last-child {
      width: 148px;
      height: 39px;
      border-radius: 20px;
      color: #ffffff;
      font-size: 18px;
      margin-top: 28px;
    }
  }
}
.emain-img {
  width: 64px;
  height: 64px;
  left: 60px;
  bottom: 84px;
}
</style>
